<template>
  <div>
    <el-form
      v-loading="loading"
      :model="dataForm"
      :rules="rules"
      ref="dataForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item
        id="uid"
        label="游戏ID"
        prop="uid"
        style="margin-bottom: 35px"
      >
        <el-col :span="6">
          <el-input
            ref="uid"
            v-model="dataForm.uid"
            maxlength="8"
            placeholder="请输入游戏ID"
            show-word-limit
          ></el-input>
        </el-col>
      </el-form-item>
      <el-form-item
        id="name"
        label="裁判名称"
        prop="name"
        style="margin-bottom: 35px"
      >
        <el-col :span="6">
          <el-input
            ref="name"
            v-model="dataForm.name"
            maxlength="10"
            placeholder="请输入裁判名称"
            show-word-limit
          ></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="裁判头像" prop="avatar" id="avatar">
        <el-upload
          class="avatar-uploader"
          :show-file-list="false"
          :before-upload="beforeTopImageUpload"
          action="#"
          drag
          :http-request="httpUploadRequest"
        >
          <img v-if="dataForm.avatar" :src="dataForm.avatar" class="avatar" />
          <div v-else>
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">拖到此处，或<em>点击上传</em></div>
          </div>
        </el-upload>
      </el-form-item>
      <el-form-item label="称号" prop="title" id="title">
        <titleManager
          :type="type"
          :value="(dataForm.title && dataForm.title._id) || ''"
          @onSelect="onTitleSelect"
        >
          <div style="width: 156px; height: 44px">
            <img
              v-if="dataForm.title && dataForm.title._id.length > 0"
              :src="dataForm.title.img"
              style="width: 156px; height: 44px"
            />
            <div
              v-else
              style="width: 156px; height: 44px; display: flex; flex-direction: column; justify-content: center; align-items: center; border: #dddddd 1px solid; border-radius: 5px"
            >
              无称号
            </div>
          </div>
        </titleManager>
      </el-form-item>
      <el-form-item
        id="declaration"
        label="宣言"
        prop="declaration"
        style="margin-bottom: 35px"
      >
        <el-col :span="12">
          <el-input
            ref="declaration"
            type="textarea"
            v-model="dataForm.declaration"
            maxlength="36"
            placeholder="请输入宣言"
            show-word-limit
          ></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="裁判介绍" prop="desc" id="quillEditorQiniu">
        <!-- 基于elementUi的上传组件 el-upload begin-->
        <el-upload
          class="avatar-uploader"
          :accept="'image/*'"
          :show-file-list="false"
          action="#"
          style="height: 0;"
          :http-request="httpUploadEditorRequest"
        >
        </el-upload>
        <!-- 基于elementUi的上传组件 el-upload end-->
        <quill-editor
          class="editor ql-editor"
          v-model="dataForm.desc"
          ref="customQuillEditor"
          :options="editorOption"
        >
        </quill-editor>
      </el-form-item>

      <el-form-item style="margin-top: 50px; text-align: center">
        <perButton
          type="primary"
          perm="armory:referee:save"
          @click="submitForm()"
          size="large"
          >提交</perButton
        >
        <el-button type="default" @click="previewVisible = true" size="large"
          >预览</el-button
        >
      </el-form-item>
    </el-form>
    <preview
      v-if="previewVisible"
      :info="dataForm"
      @closed="onPreviewClosed"
    ></preview>
  </div>
</template>

<script>
import perButton from "@/components/perm/perButton";
import titleManager from "./titleManager";
import { randomStr } from "@/utils/randomStr";
import { uploadOssUrl } from "@/utils/uploadUrl";
import preview from "./preview";
const toolbarOptions = [
  ["bold", "italic", "underline", "strike"], // toggled buttons

  [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: "ordered" }, { list: "bullet" }],
  [{ script: "sub" }, { script: "super" }], // superscript/subscript
  [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
  [{ direction: "rtl" }], // text direction
  [{ indent: "-1" }, { indent: "+1" }],
  [{ size: ["20rpx", "24rpx", "26rpx", "32rpx", "48rpx", "custom-size"] }],
  [{ header: [1, 2, 3, 4, 5, 6, false] }],

  [
    {
      color: [
        "#000000",
        "#e60000",
        "#ff9900",
        "#ffff00",
        "#008a00",
        "#0066cc",
        "#9933ff",
        "#ffffff",
        "#facccc",
        "#ffebcc",
        "#ffffcc",
        "#cce8cc",
        "#cce0f5",
        "#ebd6ff",
        "#bbbbbb",
        "#f06666",
        "#ffc266",
        "#ffff66",
        "#66b966",
        "#66a3e0",
        "#c285ff",
        "#888888",
        "#a10000",
        "#b26b00",
        "#b2b200",
        "#006100",
        "#0047b2",
        "#6b24b2",
        "#444444",
        "#5c0000",
        "#663d00",
        "#666600",
        "#003700",
        "#002966",
        "#3d1466",
        "custom-color",
      ],
    },
    { background: [] },
  ], // dropdown with defaults from theme
  [{ align: [] }],
  ["image"],
  ["clean"], // remove formatting button
];

import { mapActions } from "vuex";
export default {
  name: "referee_manager",
  components: {
    perButton,
    titleManager,
    preview,
  },
  props: {
    referee_data: {
      type: Object,
    },
    view_type: {
      type: String,
    },
  },
  computed: {
    isEdit: function() {
      if (this.view_type == "edit") {
        return true;
      }

      return false;
    },
  },
  data() {
    return {
      activity_data_preview: {},
      type: "referee",
      dataForm: {
        _id: "",
        uid: "",
        name: "",
        avatar: "",
        declaration: "",
        title: false,
        desc: "",
      },
      rules: {
        uid: [
          {
            required: true,
            message: "请输入游戏ID",
            trigger: ["blur", "change"],
          },
          {
            min: 8,
            max: 8,
            message: "长度8个字符",
            trigger: ["blur", "change"],
          },
          { validator: this.idValidator, trigger: ["blur", "change"] },
        ],
        name: [
          {
            required: true,
            message: "请输入名称",
            trigger: ["blur", "change"],
          },
          {
            min: 1,
            max: 10,
            message: "长度在 1 到 10 个字符",
            trigger: ["blur", "change"],
          },
        ],
        avatar: [{ required: true, message: "请选择头像", trigger: "change" }],
        declaration: [
          {
            required: false,
            message: "请输入宣言",
            trigger: ["blur", "change"],
          },
          {
            min: 1,
            max: 36,
            message: "长度在 1 到 36 个字符",
            trigger: ["blur", "change"],
          },
        ],
        desc: [
          {
            required: false,
            message: "请输入介绍",
            trigger: ["blur", "change"],
          },
        ],
      },
      editorOption: {
        placeholder: "请填写裁判介绍",
        modules: {
          imageResize: {
            displayStyles: {
              backgroundColor: "black",
              border: "none",
              color: "white",
            },
            modules: ["Resize", "DisplaySize", "Toolbar"],
          },
          toolbar: {
            container: toolbarOptions, // 工具栏
            handlers: {
              size: function(value) {
                if (value === "custom-size") {
                  value = prompt("请输入字体大小");
                  if (
                    !/^(([2-6][0-9])|([7][0-2])|([1][8-9]))(rpx)$/.test(value)
                  ) {
                    alert(
                      "请输入合法的字体大小，使用rpx单位， 大小在18rpx-72rpx之间, 1rpx=2px， 用于适配不同设备"
                    );
                    value = "";
                  }
                }

                this.quill.format("size", value);
              },
              color: function(value) {
                if (value === "custom-color") {
                  value = prompt("请输入十六进制的颜色");
                  if (!/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(value)) {
                    alert("请输入合法的十六进制颜色");
                    value = "";
                  }
                }

                this.quill.format("color", value);
              },
              image: function(value) {
                if (value) {
                  document
                    .querySelector("#quillEditorQiniu .avatar-uploader input")
                    .click();
                } else {
                  this.quill.format("image", false);
                }
              },
              video: function(value) {
                if (value) {
                  document
                    .querySelector("#quillEditorQiniu .avatar-uploader input")
                    .click();
                } else {
                  this.quill.format("video", false);
                }
              },
            },
          },
        },
      },
      previewVisible: false,
      loading: false,
    };
  },
  watch: {
    referee_data: {
      handler: function(val) {
        console.log("111");
        this.setFormData();
      },
      immediate: true,
    },
  },
  async mounted() {
    this.setFormData();
  },
  methods: {
    onPreviewClosed() {
      this.previewVisible = false;
    },
    idValidator(rule, value, callback) {
      if (/[^0-9,A-Z,a-z]/.test(value.trim())) {
        callback(new Error("只能包含数字和英文字母"));
      } else {
        callback();
      }
    },
    onTitleSelect(item) {
      this.dataForm.title = item || false;
    },
    setFormData() {
      let data = this.referee_data;
      if (data && data._id) {
        Object.keys(this.dataForm).forEach((key) => {
          if (data[key]) {
            this.dataForm[key] = data[key];
          }
        });

        if (data.title_info) {
          this.dataForm.title = data.title_info;
        }
      } else {
        this.dataForm = {
          _id: "",
          uid: "",
          name: "",
          avatar: "",
          declaration: "",
          title: false,
          desc: "",
        };
      }
    },
    submitForm() {
      this.$refs.dataForm.validate(async (valid, invalidFields) => {
        if (valid) {
          let d = this.getSaveData();
          let { data, errorCode } = await this.$http.armory.save(this.type, d);
          if (errorCode != "0000") {
            this.$message.error("保存失败");
            return;
          }

          this.$message({
            type: "success",
            message: "保存成功",
          });

          this.removeTabByPath("/armory/referee/add");
          this.removeTabByPath("/armory/referee/edit");
          this.$router.push("/armory/referee/index");
          this.$bus.$emit("onRefereeChanged", data);
        } else {
          console.log("error submit!!");
          console.log(invalidFields);
          let fileds = Object.keys(invalidFields);
          if (fileds.length) {
            let id1 = fileds[0];
            if (id1 == "desc") {
              id1 = "quillEditorQiniu";
            }

            if (document.getElementById(id1)) {
              document
                .getElementById(id1)
                .scrollIntoView({ behavior: "smooth" });
            }

            let refKey = id1.replace(/\./g, "_");
            if (this.$refs[refKey]) {
              this.$refs[refKey].focus();
            }
          }
          return false;
        }
      });
    },
    getSaveData() {
      let data = {
        ...this.dataForm,
      };

      delete data.title;
      if (this.dataForm.title && this.dataForm.title._id) {
        data.title_info = this.dataForm.title;
      }

      console.log(data);
      return data;
    },
    resetForm() {
      this.$refs.dataForm.resetFields();
    },
    beforeTopImageUpload(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG/PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }

      return isJPG && isLt2M;
    },
    async httpUploadRequest(item) {
      this.loading = true;
      let fileName = randomStr() + ".jpg";

      let path = uploadOssUrl().referee + fileName;
      let result = await this.$http.uploadFile(path, fileName, item.file);
      if (result) {
        this.dataForm.avatar = result;
      } else {
        this.$message.error("上传失败");
      }
      this.loading = false;
    },
    async httpUploadEditorRequest(item) {
      this.loading = true;
      let fileName = randomStr() + ".jpg";
      let path = uploadOssUrl().referee + fileName;
      let result = await this.$http.uploadFile(path, fileName, item.file);
      if (result) {
        console.log("上传成功");
        // this.$emit('upload',res, file)
        // console.log(res, file);
        //拼接出上传的图片在服务器的完整地址
        let imgUrl = result;
        let type = imgUrl.substring(imgUrl.lastIndexOf(".") + 1);
        console.log(type);
        // 获取富文本组件实例
        let quill = this.$refs.customQuillEditor.quill;
        // 获取光标所在位置
        let length = quill.getSelection().index;
        // 插入图片||视频  res.info为服务器返回的图片地址
        if (type == "mp4" || type == "MP4") {
          quill.insertEmbed(length, "video", imgUrl);
        } else {
          quill.insertEmbed(length, "image", imgUrl);
        }
        // 调整光标到最后
        quill.setSelection(length + 1);
        //取消上传动画
        this.quillUpdateImg = false;
      } else {
        this.$message.error("上传失败");
      }

      this.loading = false;
    },
    ...mapActions("tabs", [
      "handleTabsEdit",
      "addTabs",
      "handleTabsClick",
      "removeTab",
      "removeTabByPath",
    ]),
  },
};
</script>

<style src="../../editor.css"></style>
<style scoped>
.editor {
  line-height: normal !important;
  height: fit-content;
  width: 375px;
  padding: 0 !important;
}

/deep/.editor .ql-editor {
  height: 667px;
}

/deep/ .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
/deep/ .el-upload-dragger {
  width: 178px;
  height: 178px;
}
/deep/ .avatar {
  width: 178px;
  height: 178px;
  display: block;
}

/deep/ .el-upload-dragger {
  text-align: center;
}

/deep/ .el-upload-dragger img {
  margin: auto;
}

/deep/ #quillEditorQiniu .el-form-item__content {
  height: 100%;
}

/deep/ #quillEditorQiniu1 .el-form-item__content {
  height: 100%;
}

/deep/ .ql-picker-item[data-value="custom-color"] {
  background: transparent !important;
  background-color: transparent !important;
  width: fit-content;
  height: fit-content;
}

/deep/ .ql-picker-item[data-value="custom-color"]::before {
  content: "自定义颜色";
  color: black;
  width: fit-content;
}
</style>
